<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <div class="card-toolbar">
        <a
          v-if="status === 'DRAFT' || status === 'INVALID'"
          href="#"
          class="btn btn-primary font-weight-bolder font-size-sm"
          @click="create_task_clicked($event)"
        >
          <i class="menu-icon flaticon2-plus" style="font-size: 1em"></i>Ny åtgärd
        </a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
          <thead>
            <tr class="text-left">
              <th>Åtgärd</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in tasks">
              <TaskTableRow
                v-bind:key="i"
                :task="item"
                @toastr="toastr"
                @select_task_clicked="select_task_clicked"
                @delete_task_clicked="delete_task_clicked"
              />
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>
<style lang="scss" scoped>

</style>

<script>
import axios from 'axios';
import TaskTableRow from '@/view/pages/ml/todo/TaskTableRow.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'tasks-table',
  props: ['tasks', 'currentCompanyId', 'status'],
  emits: ['select_task_clicked', 'delete_task_clicked', 'create_task_clicked'],
  mixins: [ toasts ],
  components: {
    TaskTableRow
  },
  watch: {

  },
  mounted() {

  },
  methods: {
    select_task_clicked(task_id) {
      this.$emit('select_task_clicked', task_id);
    },
    delete_task_clicked(task_id) {
      this.$emit('delete_task_clicked', task_id);
    },
    create_task_clicked(e) {
      e.preventDefault();

      this.$emit('create_task_clicked');
    },

  },
  data() {
    return {
      list: []
    };
  }
};
</script>

